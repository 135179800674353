import Echo from 'laravel-echo'
import io from 'socket.io-client'
import _Vue from 'vue'

const echo = new Echo({
  client: io,
  broadcaster: 'socket.io',
  host: process.env.BROADCAST_URL,
})

export default {
  install(Vue: typeof _Vue, name = '$echo') {
    Object.defineProperty(Vue.prototype, name, { value: echo })
  },
}

export interface EchoInterface {
  channel(identfier: string): ChannelInterface
}

export interface ChannelInterface {
  socket: any
  listen(event: string, callback: Function): EchoInterface
  on(event: string, callback: Function): void
  bind(event: string, callback: Function): void
}

export { echo }
