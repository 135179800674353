import { gameCollectionStatus } from './server'

export const gameCollectionEvents = {
  PAUSE_STARTED: 'PauseStarted',
  PAUSE_ENDED: 'PauseEnded',
  UPDATE_STATUS: 'UpdateStatus',
  CRISIS_STARTED: 'CrisisStarted',
}

export const collectionStatusRouteMap = {
  [gameCollectionStatus.OPEN]: 'wait',
  [gameCollectionStatus.FINISHED]: 'finished',
}

export const apiRoutes = {
  collections: {
    view: 'gamecollections/view',
    index: 'gamecollections/',
    select: 'gamecollections/select',
  },
  game: {
    view: 'game/view',
    login: 'game/login',
    logout: 'game/logout',
  },
  challenge: {
    challenge: 'challenge/challenge',
    answer: 'challenge/answer',
    result: 'challenge/result',
  },
  crises: {
    crisis: 'crises/crisis',
    answer: 'crises/answer',
  },
  factors: {
    index: 'factors/index',
    vote: 'factors/votes',
  },
}
