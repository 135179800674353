import Vue from 'vue'
import Component from 'vue-class-component'

@Component({
  props: {
    points: {
      required: true,
      type: Number,
    },
  },
})
export default class Card extends Vue {}
