export default [
  {
    path: '/',
    name: 'splash',
    component: () => import('@views/app/splash/index.vue'),
  },
  {
    path: '/collections',
    name: 'collections',
    component: () => import('@views/app/collections/index.vue'),
  },
  {
    path: '/collection',
    component: () => import('@views/app/collection/index.vue'),
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import('@views/app/collection/login/index.vue'),
      },
      {
        path: 'game',
        component: () => import('@views/app/collection/game/index.vue'),
        children: [
          {
            path: 'challenge',
            component: () => import('@views/app/collection/game/challenge/index.vue'),
            children: [
              {
                path: 'team',
                name: 'team',
                component: () => import('@views/app/collection/game/challenge/team/index.vue'),
              },
              {
                path: 'type',
                name: 'type',
                component: () => import('@views/app/collection/game/challenge/type/index.vue'),
              },
              {
                path: 'question',
                name: 'question',
                component: () => import('@views/app/collection/game/challenge/question/index.vue'),
              },
              {
                path: 'bet',
                name: 'bet',
                component: () => import('@views/app/collection/game/challenge/bet/index.vue'),
              },
              {
                path: 'bet-points',
                name: 'betPoints',
                component: () => import('@views/app/collection/game/challenge/bet-points/index.vue'),
              },
              {
                path: 'result',
                name: 'result',
                component: () => import('@views/app/collection/game/challenge/result/index.vue'),
                children: [
                  {
                    path: 'feedback',
                    name: 'resultFeedback',
                    component: () => import('@views/app/collection/game/challenge/result/feedback/index.vue'),
                  },
                  {
                    path: 'points',
                    name: 'resultPoints',
                    component: () => import('@views/app/collection/game/challenge/result/points/index.vue'),
                  },
                ],
              },
            ],
          },
          {
            path: 'crisis',
            name: 'crisis',
            component: () => import('@views/app/collection/game/crisis/index.vue'),
          },
          {
            path: 'factors',
            name: 'factors',
            component: () => import('@views/app/collection/game/factors/index.vue'),
          },
          {
            path: 'finished',
            name: 'finished',
            component: () => import('@views/app/collection/game/finished/index.vue'),
          },
          {
            path: 'idle',
            name: 'idle',
            component: () => import('@views/app/collection/game/idle/index.vue'),
          },
          {
            path: 'pause',
            name: 'pause',
            component: () => import('@views/app/collection/game/pause/index.vue'),
          },
          {
            path: 'wait',
            name: 'wait',
            component: () => import('@views/app/collection/game/wait/index.vue'),
          },
        ],
      },
    ],
  },
]
