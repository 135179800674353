import { challengeType } from '@ts/shared/constants/server'
import { Challenge } from '@ts/shared/interfaces'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class ChallengeInfo extends Vue {
  @Prop({ type: Object }) challenge: Challenge
  @Prop({ type: Boolean, required: false, default: true }) show: boolean
  @Prop({ type: Boolean, required: false, default: true }) showPoints: boolean
  @Prop({ type: Number, required: false, default: null }) points: number

  get type(): string {
    return this.challenge.type
  }

  get factorImageUrl(): string {
    return `factors/${this.challenge.factor.reference}.png`
  }

  get coreValueImageUrl(): string {
    return `core-values/${this.coreValueReference}.svg`
  }

  get coreValueReference(): string {
    return this.challenge.coreValue.title.replace(/\s+/g, '-').toLowerCase()
  }

  get pointsValue(): number {
    return this.points !== null ? this.points : this.challenge.points
  }

  get isBehavior(): boolean {
    return this.type === challengeType.BEHAVIOR
  }
}
