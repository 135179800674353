import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class Octagon extends Vue {
  @Prop({ type: Number, default: 152 }) size: number
  @Prop({ type: String, default: null, required: false }) imageUrl: string

  get strokeWidth(): number {
    return Math.max(this.size / 20, 10)
  }

  get widthAttribute(): string {
    return `${this.size}px`
  }

  get heightAttribute(): string {
    return `${this.size}px`
  }

  get hasImage(): boolean {
    return this.imageUrl !== null
  }

  get viewBox(): string {
    return '0 0 152 152'
  }
}
