import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

function chunk(arr: any[], len: number) {
  const chunks = []
  let i = 0
  const n = arr.length

  while (i < n) {
    chunks.push(arr.slice(i, (i += len)))
  }

  return chunks
}

@Component
export default class TeamBadge extends Vue {
  @Prop({ type: Number }) player: number
  @Prop({ type: Number, default: 152 }) size: number

  private dots: any[] = []
  private containSize: number = 70

  get widthAttribute(): string {
    return `${this.size}px`
  }

  get heightAttribute(): string {
    return `${this.size}px`
  }

  get viewBox(): string {
    return '0 0 152 152'
  }

  get groupStyle(): any {
    const offset = (100 - this.containSize) / 2
    return {
      transform: `translate(${offset}%, ${offset}%)`,
    }
  }

  private getDotPosition(index: number, options: []) {
    const offset = this.containSize / (options.length + 1)
    return offset * (index + 1) + '%'
  }

  private mounted() {
    for (let i = 1; i <= this.player; i++) {
      this.dots.push(i)
    }

    this.dots = chunk(this.dots, 2)
  }
}
