import vFlits from '@components/v-flits/index.vue'
import { apiRoutes } from '@shared/constants/client'
import MixinStorage from '@ts/mixins/storage'
import { http } from '@ts/plugins/http'
import Konami from 'konami'
import Component, { mixins } from 'vue-class-component'

@Component({
  components: {
    'v-flits': vFlits,
  },
})
export default class App extends mixins(MixinStorage) {
  public mounted() {
    this.$audio.loadSet('default')
    this.enableKonami()
  }

  public playAudio() {
    this.$audio.play('open')
  }

  private async logout() {
    try {
      const data: any = await http.post(apiRoutes.game.logout)
    } finally {
      this.clearStorage()
      location.replace('/')
    }
  }

  private enableKonami() {
    const k = new Konami(async () => {
      await this.$audio.loadSet('smb')
      this.$audio.play('1up')
    })
  }
}
