import VuePortal from '@linusborg/vue-simple-portal'
import audio from '@plugins/audio'
import echo from '@plugins/echo'
import flits from '@plugins/flits'
import router from '@shared/router'
import dialog from '@ts/components/v-dialog'
import Vue from 'vue'

Vue.use(VuePortal, {
  name: 'portal',
})

Vue.use(audio)
Vue.use(dialog)
Vue.use(echo)
Vue.use(flits, { router })
