import { storageKey } from '@shared/helpers'
import store from 'store'
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class MixinStorage extends Vue {
  get challengeStorageKey() {
    return storageKey('challenge')
  }

  get factorsStorageKey() {
    return storageKey('factors')
  }

  get storedFactors() {
    return store.get(this.factorsStorageKey, {})
  }

  get hasStoredFactors(): boolean {
    return Object.keys(this.storedFactors).length > 0
  }

  get hasStoredChallenge(): boolean {
    return Object.keys(this.getStoredChallenge()).length > 0
  }

  public getStoredChallenge() {
    return store.get(this.challengeStorageKey, {})
  }

  public getStoredChallengeAttribute(attribute: string) {
    return this.getStoredChallenge()[attribute]
  }

  public storeChallengeAttribute(key: string, value: any) {
    const challenge = this.getStoredChallenge()

    challenge[key] = value
    store.set(this.challengeStorageKey, challenge)
  }

  public storeFactorsAttribute(key: string, value: any) {
    const factors = this.storedFactors

    factors[key] = value
    store.set(this.factorsStorageKey, factors)
  }

  public hasChallengeAttribute(key: string) {
    return this.getStoredChallenge().hasOwnProperty(key)
  }

  public deleteStoredChallenge() {
    store.remove(this.challengeStorageKey)
  }

  public deleteStoredFactors() {
    store.remove(this.factorsStorageKey)
  }

  public clearStorage() {
    store.clearAll()
  }
}
