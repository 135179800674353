import _Vue from 'vue'

interface LoadItem {
  src: string
  id?: string | number
  options?: RequestInit
}

export interface AudioManagerInterface {
  loadSet(name?: string): void
  play(name: string): void
}

const IS_MEDIA_PRELOAD_SUPPORTED = false

const names = ['1up', 'crisis-end', 'crisis-start', 'nopoints', 'open', 'pause', 'points', 'screen', 'wheelspin']

class AudioManager {
  private sprites: any = {}
  private currentSet: string = null
  private isLoaded: boolean = false

  public async loadSet(setName: string = 'default') {
    this.isLoaded = false

    for (const name of names) {
      const result = await this.load(name, setName)
      this.sprites[name] = result
    }

    this.currentSet = setName
    this.isLoaded = true
  }

  public async play(name: string) {
    if (!this.isLoaded) {
      await this.loadSet()
    }

    const sprite = this.sprites[name]
    if (!sprite) {
      throw Error(`Invalid sprite name ${name}`)
    }

    sprite.addEventListener('ended', async () => {
      delete this.sprites[name]

      setTimeout(async () => {
        const result = await this.load(name, this.currentSet)
        this.sprites[name] = result
      }, 100)
    })

    sprite.play()
  }

  private async localizeItem(name: string, setName: string = 'default'): Promise<string> {
    try {
      const result = await import(`@audio/${setName}/${name}.mp3`)
      return result.default
    } catch (e) {
      // nothing
    }
  }

  private async fetchItem(name: string, setName: string = 'default'): Promise<Response> {
    const path = await this.localizeItem(name, setName)
    if (!path) {
      throw new Error(`Audiosprite "${name}" does not exist.`)
    }

    return fetch(path, {})
  }

  private async load(item: string, setName: string) {
    return this.fetchItem(item, setName)
      .then((response) => response.blob())
      .then((blob) => {
        return new Promise((resolve, reject): void => {
          const audio = document.createElement('audio')

          audio.preload = 'auto'
          audio.autoplay = false

          if (IS_MEDIA_PRELOAD_SUPPORTED) {
            audio.addEventListener('canplaythrough', function handler(): void {
              audio.removeEventListener('canplaythrough', handler)
              URL.revokeObjectURL(audio.src)
              resolve(audio)
            })

            audio.addEventListener('error', function handler(): void {
              audio.removeEventListener('error', handler)
              URL.revokeObjectURL(audio.src)
              reject(audio)
            })
          }

          audio.src = URL.createObjectURL(blob)

          if (!IS_MEDIA_PRELOAD_SUPPORTED) {
            // Force the audio to load but resolve immediately as `canplaythrough` event will never be fired
            audio.load()
            resolve(audio)
          }
        })
      })
      .catch((err) => {
        if (process.env.NODE_ENV !== 'production') {
          console.error(err)
        }
      })
  }
}

const audioManager: any = new AudioManager()

export default {
  install(Vue: typeof _Vue, options: any = {}) {
    Object.defineProperty(Vue.prototype, '$audio', { value: audioManager })
  },
}

export { audioManager }
