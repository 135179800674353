/* tslint:disable */

export const gameStatus = {
  OPEN: "open",
  FINISHED: "finished",
}

export const language = {
  EN: "en",
  NL: "nl",
  DE: "de",
  FR: "fr",
  ES: "es",
}

export const challengeType = {
  FIT: "fit",
  BEHAVIOR: "behavior",
}

export const gameCollectionStatus = {
  PENDING: "pending",
  OPEN: "open",
  RUNNING: "running",
  FINISH_CHALLENGES: "finish-challenges",
  FINISHED: "finished",
  RESULTS: "results",
  CLOSED: "closed",
}
