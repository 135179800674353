import Vue from 'vue'
import vCard from '../components/v-card/index.vue'
import vChallengeInfo from '../components/v-challenge-info/index.vue'
import vDeleteConfirm from '../components/v-delete-confirm/index.vue'
import vIcon from '../components/v-icon/index.vue'
import vLoader from '../components/v-loader/index.vue'
import vOctagon from '../components/v-octagon/index.vue'
import vPromiseButton from '../components/v-promise-button/index.vue'
import vTeamBadge from '../components/v-team-badge/index.vue'

Vue.component('v-icon', vIcon)
Vue.component('v-loader', vLoader)
Vue.component('v-delete-confirm', vDeleteConfirm)
Vue.component('v-promise-button', vPromiseButton)
Vue.component('v-team-badge', vTeamBadge)
Vue.component('v-octagon', vOctagon)
Vue.component('v-card', vCard)
Vue.component('v-challenge-info', vChallengeInfo)
